import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Loading, ProgressBar } from '../../components';
import {
	setNavigatePointerNodeId,
	toggleIsCompletedNodesVisible,
	toggleIsDeletedNodesVisible,
	setUpcomingTaskModalOpen,
	setPowerLaw,
	setIsFullViewOn
} from "../../store/actions";
import { RootState } from '../../store/store'; // adjust import path as necessary
import { RoutinesAndChallenges } from './components/RoutinesAndChallenges';
import { DiferDurations } from './components/DiferDurations';
import { CurrentTask } from './components/currentTask';
import { BreadCrumbs } from './components/BreadCrumbs';
import { Icons } from '../../components/Icons';
import { confettiRise } from '../../assets/sounds';

// Define the types for component props
interface SideBarProps {}

const SideBar: React.FC<SideBarProps> = () => {
	const dispatch:any = useDispatch();
	const [parentObjectiveIndex, setParentObjectiveIndex] = useState<number>(0);
	// const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
	const isCompletedNodesVisible = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
	const isDeletedNodesVisible = useSelector((state: RootState) => state.globalStates.isDeletedNodesVisible);
	const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
	const upcomingTask = useSelector((state: RootState) => state.globalStates.upcomingTask);
	const [showPowerLaw] = useState<boolean>(false);
	const powerLaw = useSelector((state: RootState) => state.globalStates.powerLaw);
	const [isDifferMenuOpen, setIsDifferMenuOpen] = useState<boolean>(false);
	const treeData = useSelector((state: RootState) => state.tree);

	const handlePrevOrNextClick = (isNext: boolean) => {
		setParentObjectiveIndex(prev => {
			if (isNext) {
				return prev === currentTasksParents.length - 1 ? 0 : prev + 1;
			}
			return prev === 0 ? currentTasksParents.length - 1 : prev - 1;
		});
	};

	const toggleIsDifferMenuOpen = () => {
		setIsDifferMenuOpen(prev => !prev);
	};

	const handleParentObjectiveClick = () => {
		dispatch(setIsFullViewOn(false));
		dispatch(setNavigatePointerNodeId(currentTasksParents[parentObjectiveIndex]?._id));
	};

	useEffect(() => {
		setParentObjectiveIndex(currentTasksParents?.length - 1);
	}, [currentTasksParents]);

	useEffect(() => {
		if (upcomingTask?._id) {
			confettiRise.play();
			dispatch(setUpcomingTaskModalOpen(true));
		}
	}, [upcomingTask?._id, dispatch]);

	return (
		<div className="bg-gray-400 flex justify-between flex-col p-3">
			<div className='mb-2'>
				<div className='mb-4'>
					<h5>Project & Objectives</h5>
					{/* tabs  */}
					<div className='flex justify-center gap-3'>
						<span className='bg-gray-300 px-2 py-1 rounded text-xs'>Obj</span>
						<span className='px-2 py-1 rounded text-xs'>KR</span>
						<span className='px-2 py-1 rounded text-xs'>N</span>
						<span className='px-2 py-1 rounded text-xs'>M</span>
					</div>
					
					{/* text area  */}
					<div className="w-full md:w-full mb-1 mt-2 flex items-center">
						<span onClick={() => handlePrevOrNextClick(false)} className='p-1 cursor-pointer'>{"<"}</span>
						<div onClick={handleParentObjectiveClick} className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-15 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white">
						    {currentTasksParents && currentTasksParents.length !== 0 ? currentTasksParents[parentObjectiveIndex]?.objective : "Please schedule a Category of Improvement"}
						</div>
						<span onClick={() => handlePrevOrNextClick(true)} className='p-1 cursor-pointer'>{">"}</span>
					</div>

					{/* defer button and progress bar  */}
					{
						currentTasksParents?.length > 0 && <>

							{/* progress  */}
							<p className='mb-1'>Progress</p>
							<div className="h-4">
								<ProgressBar value={currentTasksParents && currentTasksParents[parentObjectiveIndex]?.completedPortion * 100} />
							</div>

							{/* defer btn  */}
							<div className='flex justify-end relative mt-2'>
								<button onClick={toggleIsDifferMenuOpen} className="w-[30px] p-2 h-[30px] flex justify-center items-center outline-none bg-green-500 rounded-full text-white shadow-lg">
									<Icons.NextDoubleWhite/>
								</button>
								{isDifferMenuOpen && <DiferDurations toggleIsDifferMenuOpen={toggleIsDifferMenuOpen} left={true} id={currentTasksParents[parentObjectiveIndex]?._id}/>}
							</div>
						</>
					}
				</div>

				<hr/>

				{
					!treeData && <div className='bg-white rounded-md mb-4 py-2'>
						<Loading />
					</div>
				}
				<hr/>
				{/* current task  */}
				<CurrentTask/>
				<hr/>
				<BreadCrumbs />
			</div>

			<hr/>

			<RoutinesAndChallenges treeData={treeData} />

			<div className="flex-1 flex flex-col justify-end w-full">
				<div className="mt-3 flex gap-1 flex-col">
					<Button onClick={() => dispatch(toggleIsCompletedNodesVisible())}>
					   {isCompletedNodesVisible ? "Hide" : "Show"} Completed
					</Button>
					<Button onClick={() => dispatch(toggleIsDeletedNodesVisible())}>
					   {isDeletedNodesVisible ? "Hide" : "Show"} Deleted
					</Button>

					<div className="flex-1">
						{
							showPowerLaw && <div className="w-full">
								<input value={powerLaw} onChange={(e) => dispatch(setPowerLaw(e.target.value))} type="number" className='w-full rounded-md px-2 py-1' />
							</div>
						}
					</div>
				</div>
			</div>

			<div className="flex items-center p-2 mt-3 space-x-4 justify-self-end">
				{/* {
					loggedInUser?.profilePic ? <>
						<img src={loggedInUser?.profilePic?.url} alt="profile" className="w-12 h-12 rounded-lg" />
					</> : <img className='w-12 h-12 rounded-lg' alt='profile-img' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEGklEQVR4nO2aS2xWRRTHf0hpSyzRPihEIVRXIChEEyJ7DLWJ2OoKH0tSVmChGzbWuBICK3wGwoIVcWmxoguliQEsatAYH4USwksCmCYmyKsPc5L/TSb92ntn5k5bNPyTSb5895w59//NnDPnnPngAf6/eBTYCOwGeoHfgOvALQ37/CvwmWReAh7hPkEN8DpwFBgBxgOH6XwBvKa5Zhy1wHbgsvNSt4FjQA/QAawEGiVbq8+rgFck0w/ccfQvAW9JdkbwInDWeYEfgE6gPmKueun+6Mx3BtjANMJ+qQ8dg2a8NdHcc/QDnXbm3zcd220R8L0M/ANsA+amNgJUAV0KDmZrAGhONXmLltsm/h14ukB+BbAT+AYYEvGbwDngELDOw+ZqYFA2B/UOpVciI3FSDjsVmoCDwGhBpBoD3pV8HpqA7xwyzWV8IttOJ4C6HFkz8ktg6L0HfAI8nDNvnUNmINZnPnK2U95KPAR8G3GGZMN0qwtWZlCy74eSaHMcu8gnXi1BIhvdBTbWOAHAOzTPl5Oa0lYP+d4ERM572Oly/MVri+1wzom5HvH/7wREbDzpEZp/kqyF/0IHvxywhAsTkbCxPmDLXypalTckaNHKB4sTEmn3sDfHSWc25Ql+JaHNnkSaExLxdeItku/LS+BGlMVabeGDBTrgUhBZ7mmzQVnzvanqmXZN+DVhuJCAxGhg+t4vPSvOKrBHD98OJNKXgMjPgTbfkZ5VmhU4oocvB076QQIilqOFoEN6VjZXIEsDLHv1haUnfyYgckVz+WKlkz5V4C89NGfyRUPCqBViNzu/rk32MKuf8xK4iZinfKwsiduBmW2No5eECOqglCVinZQQZETsnZNsLcNzqgBjSZjus4E2m6R7I8/ZfQ+myaJIzDDdUDyV5+x9keE3W+prESSuRmxl9/C2I6MCeyMPxAzdEUSsyReDnrwDMTZFcSPYqQASA6oxYnAsL0VpVCIWkjROxJIAIo9H2mhQtLqb955fBqbxk62KL5HY1eiU/ud5Qm9KyIqXGNQFEMlrL/kUVnYLkBt9slI3pq/7RAARkw1Fm3Qv+kS7LPqcDlz+FnUjfYlY029ZwPxVSvfHdf1QiBqnHeSjsBbYH3m6m84B4HkPO9ulMxhy9rSphLWm2DNTpAhbJ1wDlB32a3dN0ePNGnRjMVs+a5n+4TScrUt+eMJNU+pxF/hUL5+l7GecO5NgzHcayCdUCRZ12lOOUeBjx++Ol7mWW6x7jfFZHkO64iiFpc7SzsY4FxmqJ8VjgaE11TiuXZEUtdqzqRpyeWNMPjmtd++t0+w3Z4EXmCHUKOZfSUjAUqNtkYVWaVTr7xdHFf9DX/6OGg+blDnfF6hXcbZLZbP9gWbYeelhfWfP3lNJHfNviQfgv4B/ARxzhNGFGL+bAAAAAElFTkSuQmCC"/>
				} */}
				
				{/* add node btn */}
				{/* <button onClick={() => {
					dispatch(setAddNodeModalOpen(true));
					dispatch(setParentIdOfNodeTobeCreated(""));
				}} className="bg-green-400 px-4 py-2 rounded-md flex items-center space-x-2">
					<img alt='add Node' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAU0lEQVR4nO3TwQmAUAwFwfTfdLx7VtwvM5ACHktmAIDX7e2OtYbEKFKjSI0iNYrUKFKTK7If32MMGUUm/VvHWkNiFKlRpEaRGkVqFKn5TREAmBNcXKbvEbAKdz4AAAAASUVORK5CYII="/>
					<span>Add Node</span>
				</button> */}
				{/* share btn */}
				{/* <button className="bg-blue-400 px-4 py-2 rounded-md flex items-center space-x-2">
					<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
						<path d="M 40 0 C 34.535156 0 30.078125 4.398438 30 9.84375 C 30 9.894531 30 9.949219 30 10 C 30 13.6875 31.996094 16.890625 34.96875 18.625 C 36.445313 19.488281 38.167969 20 40 20 C 45.515625 20 50 15.515625 50 10 C 50 4.484375 45.515625 0 40 0 Z M 28.0625 10.84375 L 17.84375 15.96875 C 20.222656 18.03125 21.785156 21 21.96875 24.34375 L 32.3125 19.15625 C 29.898438 17.128906 28.300781 14.175781 28.0625 10.84375 Z M 10 15 C 4.484375 15 0 19.484375 0 25 C 0 30.515625 4.484375 35 10 35 C 12.050781 35 13.941406 34.375 15.53125 33.3125 C 18.214844 31.519531 20 28.472656 20 25 C 20 21.410156 18.089844 18.265625 15.25 16.5 C 13.71875 15.546875 11.929688 15 10 15 Z M 21.96875 25.65625 C 21.785156 28.996094 20.25 31.996094 17.875 34.0625 L 28.0625 39.15625 C 28.300781 35.824219 29.871094 32.875 32.28125 30.84375 Z M 40 30 C 37.9375 30 36.03125 30.644531 34.4375 31.71875 C 31.769531 33.515625 30 36.542969 30 40 C 30 40.015625 30 40.015625 30 40.03125 C 29.957031 40.035156 29.917969 40.058594 29.875 40.0625 L 30 40.125 C 30.066406 45.582031 34.527344 50 40 50 C 45.515625 50 50 45.515625 50 40 C 50 34.484375 45.515625 30 40 30 Z"></path>
					</svg>
					<span>Share</span>
				</button> */}
			</div>
		</div>
	);
};

export default SideBar;












// source https://tailwindcomponents.com/component/dashboard-12

