import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNode,
  copyPasteNodes,
  cutPasteNodes,
  unDeferTask,
  unDeferDescendantsTasks,
  unCompleteCompletedNodes,
  pauseTask,
  // unCompleteRecurringTask,
  // updateNode,
  setAddNodeModalOpen,
  setUpdateNodeModalOpen,
  setParentIdOfNodeTobeCreated,
  setCopiedNodeId,
  setCutNodeId,
  setDepthOfNodeTobeCreated,
  setNavigatePointerNodeId,
  setManuallyScheduledNodeId,
  setStartTime,
  addChallenge,
  addRoutine,
  setObjectiveArr,
  setDeadline,
  setCurrentTask,
  setCurrentTasksParents,
  setNodeIdToBeEdited,
  unCompleteRecurringTask,
  updateNode
} from "../../../store/actions";
import { colors } from "../colors";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../components/Icons";
import { RootState } from "../../../store/store";
import { Button, RoundButton } from "../../../components";
import moment from "moment";
import { getCurrentTask } from "../../../utils";
import { SET_IS_PAUSED } from "../../../constants";


const myColors: Record<string, string> = {};

interface NodeProps {
  d: any; // Replace 'any' with your specific type for the node data
  setCurrentNodeToRank: (node: any) => void; // Replace 'any' with the appropriate type
  height: number; // Adjust type if necessary
  copiedNodes: string[]; // Assuming copiedNodes is an array of string IDs
}

const Node: React.FC<NodeProps> = ({ d, setCurrentNodeToRank, height, copiedNodes }) => {
  const navigatorId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);
  
  const getNodeBackgroundColor = (d: any, copiedNodes: string[]): string => {
    let bg;
    if (d.depth === 3) {
      bg = colors.length > (d.depth + d.height) ? colors[d.depth + d.height] : colors[0];
      bg = d.data.color || bg;
      myColors[d.data.objective] = bg;
    } else if (d.depth > 3) {
      bg = myColors[d.parent.data.objective];
      bg = d.data.color || bg;
      myColors[d.data.objective] = bg;
    } else {
      bg = d.data.color || (colors.length > d.depth ? colors[d.depth] : colors[0]);
    }
    return copiedNodes.indexOf(d.data._id) !== -1 ? `${bg}99` : bg;
  };

  const bg = useMemo(() => getNodeBackgroundColor(d, copiedNodes), [d, copiedNodes]);

  return (
    <div title={d.data.objective} className='w-full h-full p-2 border-[1px] border-black overflow-hidden' style={{ backgroundColor: bg, border: d.data._id === navigatorId ? "10px solid black" : "1px solid black" }}>
      <div className="flex h-full flex-col">
        <div className="flex-1">
          {d.depth === 1 ? <LifeDivision d={d} /> : <Other height={height} d={d} setCurrentNodeToRank={setCurrentNodeToRank} />}
        </div>
        <div className="">
          <CrudButtons d={d} setCurrentNodeToRank={setCurrentNodeToRank} />
        </div>
      </div>
    </div>
  );
}

export default Node;



interface LifeDivisionProps {
  d: {
    data: {
      _id: any;
      objective: string; // Adjust type based on your actual data structure
      // Add other properties if needed
    };
    depth: number; // Assuming depth is a number, adjust if needed
  };
}

const LifeDivision: React.FC<LifeDivisionProps> = ({ d }) => {
  const dispatch:any = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d.data?._id));
  };


  const handleOnTextClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
      const txt = e.currentTarget.innerText;
      if (txt) {
        navigator.clipboard.writeText(txt);
        toast.success("Copied!");
      }
      return;
  };

  return (
    <div className="flex flex-col">
      <div className="">{d.data.objective}</div>
      <div className="flex flex-col flex-1 pl-3 gap-3">
        <div className="h-[50%] flex flex-col">
          <p className="mb-1">Vision</p>
          <div onClick={handleClick} className="flex-1 bg-gray-200 px-3 mt-1 rounded-md overflow-y-auto">
            Not Set
          </div>
        </div>
        <div className="h-[50%] flex flex-col">
          <p className="mb-1">Purpose</p>
          <div onClick={handleClick} className="flex-1 bg-gray-200 px-3 mt-1 rounded-md overflow-y-auto">
            Not Set
          </div>
        </div>
        {window.location.hostname === "localhost" && (
            <div className="flex gap-2 items-top other-info">
              <p className="mb-0 font-bold text-gray-200">_id: </p>
              <div onClick={handleOnTextClick} className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                {d.data._id}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

interface OtherProps {
  d: {
    data: any;
    parent: any;
    depth: number;
    children?: any; // Adjust the type based on the actual children structure
    descendants: () => Array<{ data: { _id: string } }>; // Adjust if necessary
  };
  height?: any;
  setCurrentNodeToRank?: any;
}

const Other: React.FC<OtherProps> = ({ d }) => {
  const { data } = d;
  const dispatch:any = useDispatch();
  const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId);
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const [showChallengeDetail, setShowChallengeDetail] = useState<boolean>(false);
  const navigation = useNavigate();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const handleOnTextClick = (e: React.MouseEvent<HTMLDivElement>, txt: string) => {
    e.stopPropagation();
    if (txt === "_id") {
      const txt = e.currentTarget.innerText;
      if (txt) {
        navigator.clipboard.writeText(txt);
        toast.success("Copied!");
      }
      return;
    }
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d.data._id));
  };

  const handleActionNow = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (currentTask?.startTime) {
        const funcs = {
            preRun: () => {},
            runIfSuccess: () => {},
            runIfFails: () => {}
        }
        dispatch(pauseTask(currentTask._id, "", false,funcs));
        dispatch(setStartTime(null));
        console.log("Task has been paused here");
      }
    
      // create arry of parent objective from item to project 
      let parentObjectives = [];
      let tempNode = d.parent;

      while (tempNode.depth > 2) {
          parentObjectives.push(tempNode.data);
          tempNode = tempNode.parent;
      }

      // find current task and paretn objective 
      const result = getCurrentTask(d.data,parentObjectives);
      
      dispatch({ type: SET_IS_PAUSED, payload: true });
      dispatch(setCurrentTask(result.currentTask));
      dispatch(setCurrentTasksParents(result.parentObjectives));

      if(!manuallyScheduleNodeId || manuallyScheduleNodeId[manuallyScheduleNodeId.length-1] !== d.data._id){
        if(manuallyScheduleNodeId){
          dispatch(setManuallyScheduledNodeId([...manuallyScheduleNodeId,d.data._id]));
        }
        else {
          dispatch(setManuallyScheduledNodeId([d.data._id]));
        }
      }
  };

  const handleUnDefer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (d.children === undefined) {
      dispatch(unDeferTask(d.data.deferedInheritedFrom));
    } else {
      const ids = d.descendants().map(item => item.data._id);
      dispatch(unDeferDescendantsTasks(ids));
    }
  };

  const clickHandle = (e: React.MouseEvent<HTMLDivElement>, txt: string) => {
    if (txt === "tempDeadline") {
      setTimeout(() => {
        dispatch(setNavigatePointerNodeId(d.data.inheritedFrom));
      }, 1000);
    } else {
      handleOnTextClick(e, txt);
    }
  };

  const handleUnCompleteRecurringTask = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const isConfirm = window.confirm("Do You Want to Un Complete This");

    if (isConfirm) {
      if (d.data.recurring?.days && d.data.recurring.days.length > 0) {
        dispatch(unCompleteRecurringTask(d.data._id));
      } 
      else {
        dispatch(updateNode({ ...d.data, isCompleted: false }));
      }
    }
  };

  const handleAddChallenge = (e: React.MouseEvent<HTMLButtonElement>,type: "day" | "week") => {
    e.stopPropagation();

    dispatch(addChallenge({ type, node: d.data._id }));
    setShowChallengeDetail(false);
  };

  const handleAddRoutine = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(addRoutine({ node: d.data._id }));
    setShowChallengeDetail(false);
  };

  const handleShowHideInfo = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {d.depth >= 3 && (
          <div className="w-full h-3 rounded-md bg-blue-600/30">
            <div style={{ width: `${data.completedPortion * 100}%` }} className="rounded-md bg-blue-600 text-white text-[8px] pl-2 text-center">
              {parseFloat(`${data.completedPortion * 100}`).toFixed(2)}%
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <div onClick={(e) => clickHandle(e, "objective")} className="bg-gray-200 text-black rounded-md px-2">
            {d.data.objective}
          </div>
          <div onClick={handleShowHideInfo} className="bg-gray-200 text-black rounded-md px-2">
            {showInfo ? "Hide" : "Show"} Info
          </div>
          {window.location.hostname === "localhost" && (
            <div className="flex gap-2 items-top other-info">
              <p className="mb-0 font-bold text-gray-200">_id: </p>
              <div onClick={(e) => clickHandle(e, "_id")} className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                {d.data._id}
              </div>
            </div>
          )}
          {showInfo && (
            <>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">relativeImpactNodeValue: </p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {d.data.relativeImpactNodeValue}
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">relativeImpactValueNodeAsScale10to0: </p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {d.data.relativeImpactValueNodeAsScale10to0}
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">relativeSizeOfImpactNodeValue: </p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {d.data.relativeSizeOfImpactNodeValue}
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">relativeSpeedOfImpactNodeValue: </p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {d.data.relativeSpeedOfImpactNodeValue}
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">Deadline: </p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {
                    d.data.deadline? moment(d.data.deadline).format("ll"):"Not Set"
                  }
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">TempDeadline: </p>
                <div onClick={(e)=> {e.stopPropagation();dispatch(setNavigatePointerNodeId(d.data.inheritedFrom))}} className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {
                    d.data.tempDeadline? moment(d.data.tempDeadline).format("ll"):"Not Set"
                  }
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">NPV:</p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {d.data.nodeProductivityValue}
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">INPV:</p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  {1/d.data.nodeProductivityValue}
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <p className="mb-0 font-bold text-gray-200">NPV Calc:</p>
                <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto text-xs">
                  <span>REDVNAS10: {d.data.relativeEstDurationValueNodeAsScale10to0}</span> <br/>
                  <span>RIVNAS10: {d.data.relativeImpactValueNodeAsScale10to0}</span> <br/>
                  <span>relativeProductivityValue: REDVNAS10/RIVNAS10</span> <br/>
                  <span>temporalValue: {(d.data.totalEstimatedTaskDuration || d.data.estimatedTaskDuration) / d.data.netRemainingMinutes}</span> <br/>
                  <span>NPV: {(d.data.relativeEstDurationValueNodeAsScale10to0/d.data.relativeImpactValueNodeAsScale10to0) / ((d.data.totalEstimatedTaskDuration || d.data.estimatedTaskDuration) / d.data.netRemainingMinutes)}</span>
                </div>
              </div>
              <div className="flex gap-2 items-top other-info">
                <div onClick={(e) => { e.stopPropagation(); navigation(`/ex-duration/${d.data._id}`, { state: { node: d.data } }); }} className="cursor-pointer flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                  View Exploitation Duration
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex justify-between">
          <div className="flex-1 flex flex-wrap gap-1">
            {d.data.isDefered && (
              <Button onClick={handleUnDefer} title="Un Defer">
                D
              </Button>
            )}
            {d.data.isCompleted && (
              <Button onClick={handleUnCompleteRecurringTask} style={{width: d.data.isRecurringCompleted? "210px":"170px"}} title="Un Complete this task">
                 {d.data.isTempCompleted ? "Temp" : ""} {d.data.isRecurringCompleted ? "Recurring" : ""} Completed
              </Button>
            )}
            {d.data.isDeleted && (
              <Button style={{width: "90px",backgroundColor:'#ff0000'}}>
                  Deleted
              </Button>
            )}
            {d.data._id === cutNodeId && (
                <Button style={{opacity: .4}}>
                  Cut
                </Button>
            )}
            {d.data._id === copyNodeId && (
                <Button style={{opacity: .4}}>
                  Copied
                </Button>
            )}

            <Button onClick={handleActionNow}>
              Action Now
            </Button>

            <div className="left-2">
              <Button onClick={(e) => { e.stopPropagation(); setShowChallengeDetail(p => !p); }}>
                Assign
              </Button>

              {showChallengeDetail && (
                <>
                  <div className="flex flex-col gap-2 mt-2 bg-white p-2 rounded-md">
                    <Button onClick={(e)=>handleAddChallenge(e,"day")}>Daily Challenge</Button>
                    <Button onClick={(e)=>handleAddChallenge(e,"week")}>Weekly Challenge</Button>
                    <Button onClick={handleAddRoutine}>Routine</Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {d.children && (
            <p className="text-[12px] text-white font-bold text-end mb-0">
              TEPP: {parseFloat(`${d.data.estimatedProductivityPoints}`).toFixed(0)}
            </p>
          )}

          {d.children && (
            <p className="ml-5 text-[12px] text-white font-bold text-end mb-0">
              TED: {d.data.totalEstimatedTaskDuration}
            </p>
          )}

          {!d.children && (
            <p className="text-[12px] text-white font-bold text-end mb-0">
              EPP: {parseFloat(`${d.data.estimatedProductivityPoints}`).toFixed(0)}
            </p>
          )}

          {!d.children && (
            <p className="ml-5 text-[12px] text-white font-bold text-end mb-0">
              ED: {d.data.estimatedTaskDuration}
            </p>
          )}
        </div>
      </div>
    </>
  );
};


interface CrudButtonsProps {
  d: any;
  setCurrentNodeToRank: (node: any) => void; // Replace `any` with the actual type if possible
}

const CrudButtons: React.FC<CrudButtonsProps> = ({ d, setCurrentNodeToRank }) => {
  const dispatch:any = useDispatch();
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId); // Replace `any` with your Redux state type
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const [showMenu, setShowMenu] = useState<boolean>(false);


  const handleCrudOperations = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowMenu(false);
    const operation = e.currentTarget.title;

    switch (operation) {
      case "Update":
        dispatch(setUpdateNodeModalOpen(true));
        dispatch(setNodeIdToBeEdited(d.data._id));
        break;
      case "Delete":
        if (window.confirm("Are You Sure")) {
          dispatch(deleteNode(d.data._id, d.data.parent));
          dispatch(setNavigatePointerNodeId(d.parent.parent.data._id));
        }
        break;
      case "Copy":
        dispatch(setCopiedNodeId(d.data._id));
        break;
      case "Cut":
        dispatch(setCutNodeId(d.data._id));
        break;
      case "Paste":
        if (cutNodeId) {
          dispatch(cutPasteNodes(cutNodeId, d.data._id));
          dispatch(setCutNodeId(null));
        }
        if (copyNodeId) {
          dispatch(copyPasteNodes(copyNodeId, d.data._id));
          // dispatch(setCopiedNodeId(null));
        }
        break;
      case "Rank":
        setCurrentNodeToRank(d);
        break;
      case "Un Complete All Children":
        setShowMenu(false);
        const arrOfIds = d.descendants().filter((item:any) => item.data.isCompleted).map((item:any) => item.data._id);
        if (arrOfIds.length > 0) {
          dispatch(unCompleteCompletedNodes(arrOfIds));
        }
        break;
      default:
        break;
    }
  };

  const handleCreateNode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    
    dispatch(setObjectiveArr(null));
    dispatch(setDeadline(null));
    setShowMenu(false);
    dispatch(setAddNodeModalOpen(true));
    dispatch(setParentIdOfNodeTobeCreated(d.data._id));
    dispatch(setDepthOfNodeTobeCreated(d.depth + 1));
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const buttons = [
    {
      label: <Icons.MenuWhite/>,
      operation: "Menu",
    },
    {
      label: <Icons.PlausWhite/>,
      operation: "Create",
    },
    {
      label: "Delete",
      operation: "Delete",
    },
    {
      label: "Copy",
      operation: "Copy",
    },
    {
      label: "Cut",
      operation: "Cut",
    },
    {
      label: "Paste",
      operation: "Paste",
    },
    {
      label: "Rank",
      operation: "Rank",
    },
    {
      label: "Un Complete All Children",
      operation: "Un Complete All Children",
    },
  ];

  return (
    <>
      <div className="crud-buttons">
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex-1">
            {showMenu && (
              <div className="w-[230px] mb-2 p-2 text-gray-600 bg-gray-200 min-w-[190px] bottom-[60px] flex flex-col rounded-md">
                {buttons.slice(2, 8).map(item => (
                  <button
                    key={item.operation}
                    title={item.operation}
                    onClick={handleCrudOperations}
                    className="py-2 cursor-pointer hover:bg-gray-400 hover:text-white px-2"
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            )}
            <RoundButton onClick={handleMenuClick} style={{width: "40px",height: "40px",backgroundColor: 'transparent'}} title="Menu Open">
              <Icons.MenuWhite/>
            </RoundButton>
          </div>

          <div className="right">
            <RoundButton onClick={handleCreateNode} style={{width: "40px",height: "40px",backgroundColor: 'transparent'}} title="Create">
                <Icons.PlausWhite/>
            </RoundButton>
          </div>
        </div>
      </div>
    </>
  );
};








